import io from 'socket.io-client'
import { API_URL_SOKET } from "@src/constants"

export async function logoutUser(dispatch) {
  dispatch({ type: 'LOGOUT' })
  try {
    localStorage.removeItem('@TOKEN')
    localStorage.removeItem('@USER')
  } catch (error) {
    // Handle Error
  }
}

export function getlocalModules() {
  try {
    const dat = localStorage.getItem('@currentAccess')
    return dat === null ? [] : dat
  } catch (error) {
    // Handle Error
  }
}

export const GetNotifications = (dispatch, u) => {
  console.log("init socket.oi: ")
  const options = {
      transports: ["websocket"]
  }
  const socketIo = io.connect( API_URL_SOKET, options) 
  socketIo.on('connect', function (data) {
    console.log('socket ON connect')
    console.log('Conneted to socket.oi: ', socketIo.id)
    console.log('Conneted user idCompany: ', u.idCompany)
  })
  // socketIo.on("join",function (n) {
  //   console.log("Data: chat general", n)
  // })
  // socketIo.on("chat", function (n) {
  //   console.log("Data chat: chat general", n)
  // })
  dispatch({ type:'SOCKETIO', payload: { socketIo } })
}