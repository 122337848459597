import React, { useEffect, useReducer } from "react"
// Reducers
import { AuthReducer } from './reducer'
import { GetNotifications } from '@context/auth/action'

export const AuthStateContext = React.createContext()


export const AuthProvider = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    errorMessage: null,
    successMessage: null,
    socketIo: null,
    notifications:[]
  }

  const [authState, dispatch] = useReducer(AuthReducer, initialState)

  useEffect(() => {
    (async function() {
      try {
        const token = window.localStorage.getItem('@TOKEN')

        if (token !== null) {
          const user = JSON.parse( window.localStorage.getItem('@USER') )
          dispatch({ type:'USER_INFO', payload: { user } })
          GetNotifications( dispatch, user )
        }
      } catch (error) {
        //
      }
    })()
  }, [])

  return (
    <AuthStateContext.Provider value={{ ...authState, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  )
}
